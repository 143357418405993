var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ADialogLayout",
    {
      ref: "dialog",
      attrs: { title: "Gebruiker Importeren", hasConfirm: false },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "tw-flex tw-justify-between tw-p-16" },
                [
                  _c(
                    "AButton",
                    {
                      staticClass: "tw-bg-transparent tw-text-black-400",
                      on: { click: _vm.closeFileImportDialog }
                    },
                    [_vm._v(" Annuleren ")]
                  ),
                  _vm.importFileStep === 1
                    ? [
                        _c(
                          "AButton",
                          {
                            staticClass: "tw-bg-primary-400",
                            nativeOn: {
                              click: function($event) {
                                _vm.importFileStep = 2
                              }
                            }
                          },
                          [_vm._v(" Volgende ")]
                        )
                      ]
                    : _vm._e(),
                  _vm.importFileStep === 2
                    ? [
                        _c(
                          "AButton",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.uploadQueue.length,
                                expression: "uploadQueue.length"
                              }
                            ],
                            staticClass: "tw-bg-primary-400",
                            nativeOn: {
                              click: function($event) {
                                return _vm.postUsersFile()
                              }
                            }
                          },
                          [_vm._v(" Opslaan ")]
                        )
                      ]
                    : _vm._e(),
                  _vm.importFileStep === 3
                    ? [
                        _c(
                          "AButton",
                          {
                            staticClass: "tw-bg-primary-400",
                            nativeOn: {
                              click: function($event) {
                                return _vm.closeFileImportDialog($event)
                              }
                            }
                          },
                          [_vm._v(" Sluiten ")]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-stepper",
        {
          attrs: { color: "primary" },
          model: {
            value: _vm.importFileStep,
            callback: function($$v) {
              _vm.importFileStep = $$v
            },
            expression: "importFileStep"
          }
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.importFileStep > 1,
                    step: "1",
                    color: "secondary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.gotoStepOne()
                    }
                  }
                },
                [_vm._v("Download template")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.importFileStep > 2,
                    step: "2",
                    color: "secondary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.gotoStepTwo()
                    }
                  }
                },
                [_vm._v("Upload bestand")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { step: "3", color: "secondary" } },
                [_vm._v("Upload bevestiging")]
              )
            ],
            1
          ),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mb-12 stepper-container",
                      attrs: { height: "50vh" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "stepper-content" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "ma-0": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "text-center": "", xs12: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "download-icon" },
                                    [_vm._v("download")]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "Download het bestand en voeg de gebruikers toe aan het bestand."
                                    )
                                  ]),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "secondary download-button",
                                        "m-64": "",
                                        href: "/files/gebruikerstemplate.xlsx",
                                        type:
                                          "“application/vnd.openxmlformats-officedocument.spreadsheetml.sheet”",
                                        download: "gebruikerstemplate.xlsx"
                                      }
                                    },
                                    [_vm._v(" Download ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mb-12 stepper-container",
                      attrs: { height: "50vh" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "file-drop stepper-content",
                          attrs: { "fill-height": "", "pa-1": "", wrap: "" }
                        },
                        [
                          _c("MiFileUpload", {
                            ref: "fileUpload",
                            attrs: {
                              fileIcon: "mdi-file-excel",
                              acceptedFileTypes: [
                                "application/vnd.ms-excel",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ],
                              clearAfterUpload: false,
                              multiple: false,
                              filePath: _vm.uploadedFile
                            },
                            on: {
                              itemDropped: function($event) {
                                return _vm.handleItemDropped($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-12", attrs: { height: "50vh" } },
                    [
                      _c(
                        "div",
                        { staticClass: "overflow-auto results-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "response-title dialog-title" },
                            [
                              _c("div", { attrs: { xs8: "" } }, [
                                _vm._v("Email")
                              ]),
                              _c("div", { attrs: { xs4: "" } }, [
                                _vm._v("Status")
                              ])
                            ]
                          ),
                          _vm._l(Object.keys(_vm.userImportResponse), function(
                            response,
                            index
                          ) {
                            return _c(
                              "v-layout",
                              { key: "import" + index, attrs: { wrap: "" } },
                              _vm._l(_vm.userImportResponse[response], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: index,
                                    attrs: { xs12: "", "pa-0": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "response-container" },
                                      [
                                        _c("div", { attrs: { xs8: "" } }, [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item ? item : "-") +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              xs4: "",
                                              "text-xs-right": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class:
                                                  "response-row response-row--" +
                                                  _vm.getResponseStatusColor(
                                                    response
                                                  )
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: _vm.getResponseStatusColor(
                                                        response
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getResponseStatusIcon(
                                                          response
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getResponseStatusText(
                                                        response
                                                      )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }