import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { User, userRoles, UserRole, UserType, excludeUserTypes } from '@/models/User';
import CreateUserDialog from '@/views/Users/CreateUserDialog/CreateUserDialog.vue';
import { Token } from '@/support/Token';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusLabel } from '@/support/ReportStatus';
import { UserFilter } from '@/models/UserFilter';
import { formatDate } from '@/support/String';
import ImportUsersDialog from '@/views/Users/ImportUsersDialog/ImportUsersDialog.vue';
import { ADialog } from '@/support/ADialog';

@Component<UserManagement>({})
export default class UserManagement extends Vue {
  // #region @Props

  @Prop({ default: false })
  protected isSimpleUserManagement!: boolean;

  @Prop({ default: () => [] })
  protected userTypes!: UserType[];

  @Prop({ default: 'Gebruikersbeheer' })
  protected title!: string;

  // #region @Refs

  @Ref()
  protected usersDatatable!: DataTable;

  // #endregion

  // #region Class properties

  public $pageTitle = this.title;

  protected activeChoices = [
    { name: 'Actief', value: true },
    { name: 'Niet actief', value: false },
  ];

  protected mfaChoices = [
    { name: 'MFA actief', value: true },
    { name: 'MFA niet actief', value: false },
  ];

  protected tableFilters: UsersTableFilter = {
    types: this.userTypes.map((type: UserType) => type.key),
  };

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  }

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.emitBreadcrumb();
  }

  // #endregion

  // #region Class methods

  protected emitBreadcrumb(): void {
    let currentStatus = this.currentStatus;
    if (! currentStatus || ! currentStatus.length) {
      currentStatus = 'Gebruikersbeheer';
    }
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: currentStatus === 'Gebruikersbeheer' ? currentStatus : getStatusLabel((currentStatus as string)) },
        ],
      });
  }

  protected openCreateUserDialog(): void {
    ADialog.open(CreateUserDialog, {
      props: {
        userTypes: this.userTypes,
      },
      listeners: {
        refreshTable: this.refreshDataTable,
      },
    });
  }

  protected openImportUsersDialog(): void {
    ADialog.open(ImportUsersDialog, {
      props: {
        userTypes: this.userTypes,
        wide: true,
      },
      listeners: {
        refreshTable: this.refreshDataTable,
      },
    });
  }

  protected updateStoreFilters(): void {
    this.$store.dispatch('filtersUpdate', { gebruikersbeheer: this.tableFilters });
  }

  protected refreshDataTable(): void {
    this.usersDatatable?.refresh();
  }

  public redirect(): void {
    this.$router.push(
      this.$route.query.redirect
        ? `${this.$route.query.redirect}`
        : '/reports',
    );
  }

  // #endregion

  // #region Async methods

  protected async loginAs(user: User): Promise<void> {
    await new User()
      .loginAs(user.uuid as string)
      .then((user: User) => {
        if (! user || ! user.token) {
          return;
        }
        Token.delete(['access', 'refresh']);
        Token.set({ access: (user.token as any).bearer });
        new User(user).current().then((user: User) => {
          this.$store.dispatch('userAuthenticated', user);
          this.getUserFilters();
          this.redirect();
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getUserFilters(): Promise<void> {
    await new UserFilter()
      .limit(5000)
      .all()
      .then((userFilters: UserFilter[]) => {
        this.$store.dispatch('changeUserFilters', userFilters);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters

  protected get tableOptions(): TableOptions {
    return {
      model: new User().filter(this.dataTableFilters).include('token'),
      name: {
        singular: 'Gebruiker',
        plural: 'Gebruikersbeheer',
      },
      headers: [
        {
          text: 'Naam',
          value: 'name',
          action: '/users/{id}',
          sortable: {
            key: 'name',
            order: 'ASC',
          },
        },
        {
          text: 'Inlog email',
          value: 'email',
          visible: () => ! this.isWerkvoorbereidingPlanning,
        },
        {
          text: 'Zakelijk email',
          value: 'business_email',
        },
        {
          text: 'Type',
          value: 'type',
          transform: (key: string) => this.userTypes.find((user: any) => user.key === key)?.name || '',
          visible: () => ! this.isWerkvoorbereidingPlanning,
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Laatst ingelogd',
          value: 'last_login_at',
          sortable: {
            key: 'last_login_at',
            order: 'ASC',
          },
          transform: (last_login_at: string) => (last_login_at && last_login_at !== '0000-00-00 00:00:00' ? formatDate(last_login_at) : '-'),
        },
        {
          text: '2fa',
          value: 'mfa_token',
          transform: (value: boolean) => (value
            ? '<i data-v-66e85e7e="" aria-hidden="true" class="v-icon icon--left material-icons theme--light">check</i>'
            : ''),
          visible: () => ! this.isWerkvoorbereidingPlanning,
        },
        {
          text: 'Is actief',
          value: 'is_active',
          transform: (value: boolean) => (value
            ? '<i data-v-66e85e7e="" aria-hidden="true" class="v-icon icon--left material-icons theme--light">check</i>'
            : ''),
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: '/users/{id}',
        },
        {
          type: 'loginAs',
          label: 'loginAs',
          icon: 'input',
          action: (vm: any, user: User) => {
            this.loginAs(user);
          },
          visible: (vm: any, user: User) => user.hasBearerToken,
        },
      ],
    };
  }

  protected get currentStatus(): string | (string | null)[] {
    return this.$route.query.status;
  }

  protected get isAdmin(): boolean {
    if (! this.$store.state.Auth) {
      return false;
    }

    return !! this.$store.state.Auth.hasRole(userRoles.AdminRoles);
  }

  protected get isWerkvoorbereidingPlanning(): boolean {
    if (! this.$store.state.Auth) {
      return false;
    }

    return !! this.$store.state.Auth.hasRole([UserRole.WERKVOORBEREIDING, UserRole.PLANNING]);
  }

  private get dataTableFilters(): UsersTableFilter {
    return this.tableFilters;
  }

  // #endregion

  // #region @Watchers

  @Watch('$route')
  public routeChanged(): void {
    this.emitBreadcrumb();
  }

  // #endregion
}

export interface UsersTableFilter {
  status?: string;
  expert?: string;
  organization?: string;
  type?: string;
  types?: string[];
  tags?: string[];
}
