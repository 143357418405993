import ADialogLayout, { ADialogLayoutState } from '@/components/ADialogLayout/ADialogLayout';
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { User, excludeUserTypes, userTypes, UserType } from '@/models/User';
import _, { debounce } from 'lodash';

@Component<CreateUserDialog>({})
export default class CreateUserDialog extends Vue {
  @Prop({ default: [] })
  protected userTypes!: UserType[];

  // #region @Refs

  @Ref()
  protected dialog!: ADialogLayout;

  // #endregion

  // #region Class properties

  protected user: User = new User();

  protected checkbox = false;

  protected experts: User[] = [];

  protected selectedExpert = '';

  protected debouncedSearch: Function = debounce(this.getExperts, 300);

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.getExperts();
  }

  // #endregion

  // #region Class methods

  protected close(): void {
    this.$emit('input', false);
  }

  // #endregion

  // #region Async methods

  protected async createUser(): Promise<void> {
    try {
      await this.user.create();
      this.dialog.setADialogState(ADialogLayoutState.SUCCESS,
        'De gebruiker is succesvol toegevoegd.',
        'Gelukt!');
      this.$emit('refreshTable', false);
    } catch (error) {
      this.dialog.setADialogState(ADialogLayoutState.ERROR,
        'Probeer het opnieuw',
        'Er ging iets fout');
    }
  }

  protected async getExperts(query?: any): Promise<void> {
    const filters: { search?: string } = {};

    if (query && query.target && query.target.value) {
      filters.search = query.target.value;
    }

    try {
      const experts: User[] = await new User().filter(filters).limit(500).all();
      this.experts = experts;
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  // #endregion
}
