import { Str } from '@atabix/core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ADialog } from '@/support/ADialog';
import AMessageDialogIcon from '@/components/AMessageDialogIcon/AMessageDialogIcon.vue';

@Component<ADialogLayout>({
  components: {
    AMessageDialogIcon,
  },
})
export default class ADialogLayout extends Vue {
  // #region @Props

  @Prop({ default: 'Annuleren' })
  protected cancelLabel!: string;

  @Prop({ default: '' })
  protected confirmIcon!: string;

  @Prop({ default: 'Toepassen' })
  protected confirmLabel!: string;

  @Prop({ default: 'Terug' })
  protected returnLabel!: string;

  @Prop({ default: 'mdi-close-circle' })
  protected closeIcon!: string;

  @Prop({ default: true })
  protected valid!: boolean;

  @Prop({ default: '' })
  protected subtitle!: string;

  @Prop({ default: 'Titel' })
  protected title!: string;

  @Prop({ default: true })
  protected hasFooter!: boolean;

  @Prop({ default: true })
  protected hasConfirm!: boolean;
  // #endregion

  // #region Props
  protected dialogState: ADialogLayoutState = ADialogLayoutState.DEFAULT;

  protected loading = false;

  protected message = '';

  protected stateTitle = '';

  // #endregion

  // #region Controls
  public setADialogState(state: ADialogLayoutState, message?: string, title?: string): void {
    this.dialogState = state;
    this.stateTitle = title || '';
    this.message = message || '';
    this.loading = false;
  }
  // #endregion

  // #region Handlers

  protected onConfirmButtonClick(): void {
    this.loading = true;

    if (this.dialogState === ADialogLayoutState.DEFAULT) {
      const event: ADialogLayoutConfirmEvent = {
        dialogState: this.dialogState,
        setSuccessState: (message?: string, title = 'Success') => this.setADialogState(ADialogLayoutState.SUCCESS, message, title),
        setWarningState: (message?: string, title = 'Warning') => this.setADialogState(ADialogLayoutState.WARNING, message, title),
        setErrorState: (message = 'An unexpected error has occured.', title = 'Error') => this.setADialogState(ADialogLayoutState.ERROR, message, title),
        setDefaultState: () => this.setADialogState(ADialogLayoutState.DEFAULT),
      };
      this.$emit('confirm', event);
    } else {
      this.loading = false;
      this.dialogState = ADialogLayoutState.DEFAULT;
    }
  }

  protected onCloseButtonClick(): void {
    if (this.loading) { return; }
    this.$emit('close');
    ADialog.close();
  }

  // #endregion

  // #region Getters

  get stateMessage(): string {
    return Str.purify(this.message);
  }

  protected get isButtonConfirmDisabled(): boolean {
    return this.loading || ! this.valid;
  }

  // #endregion
}

// #region Interfaces
export interface ADialogLayoutConfirmEvent {
  dialogState: ADialogLayoutState
  setSuccessState: (message?: string, title?: string) => void | Promise<void>;
  setErrorState: (message?: string, title?: string) => void | Promise<void>;
  setWarningState: (message?: string, title?: string) => void | Promise<void>;
  setDefaultState: () => void | Promise<void>;
}

export interface ADialogLayoutOptions {
  cancelLabel?: string;
  confirmLabel?: string;
  confirmIcon?: string;
  closeIcon?: string;
  closeDisabled?: boolean;
  valid?: boolean;
  subtitle?: string;
  title?: string;
}

export enum ADialogLayoutState {
  DEFAULT = 'default',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}
// #endregion
