var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ACard",
    {
      attrs: { title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              !_vm.isSimpleUserManagement && _vm.isAdmin
                ? _c(
                    "AButton",
                    {
                      attrs: { "prepend-inner-icon": "file_upload" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.openImportUsersDialog($event)
                        }
                      }
                    },
                    [_vm._v(" Importeren ")]
                  )
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "AButton",
                    {
                      attrs: { "prepend-inner-icon": "add" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.openCreateUserDialog($event)
                        }
                      }
                    },
                    [_vm._v("Gebruiker aanmaken")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "AStatefulFormForFilters",
        [
          !_vm.isWerkvoorbereidingPlanning
            ? _c("AAutocompleteInput", {
                attrs: {
                  clearable: "",
                  "hide-details": "",
                  "item-text": "name",
                  "item-value": "key",
                  items: _vm.userTypes,
                  label: "Gebruikerstype"
                },
                on: {
                  change: _vm.updateStoreFilters,
                  "click:clear": _vm.updateStoreFilters
                },
                model: {
                  value: _vm.tableFilters.type,
                  callback: function($$v) {
                    _vm.$set(_vm.tableFilters, "type", $$v)
                  },
                  expression: "tableFilters.type"
                }
              })
            : _vm._e(),
          _c("ASelectInput", {
            attrs: {
              clearable: "",
              "hide-details": "",
              "item-text": "name",
              "item-value": "value",
              items: _vm.activeChoices,
              label: "actief"
            },
            on: {
              change: _vm.updateStoreFilters,
              "click:clear": _vm.updateStoreFilters
            },
            model: {
              value: _vm.tableFilters.is_active,
              callback: function($$v) {
                _vm.$set(_vm.tableFilters, "is_active", $$v)
              },
              expression: "tableFilters.is_active"
            }
          }),
          !_vm.isWerkvoorbereidingPlanning
            ? _c("ASelectInput", {
                attrs: {
                  clearable: "",
                  "hide-details": "",
                  "item-text": "name",
                  "item-value": "value",
                  items: _vm.mfaChoices,
                  label: "mfa"
                },
                on: {
                  change: _vm.updateStoreFilters,
                  "click:clear": _vm.updateStoreFilters
                },
                model: {
                  value: _vm.tableFilters.has_mfa,
                  callback: function($$v) {
                    _vm.$set(_vm.tableFilters, "has_mfa", $$v)
                  },
                  expression: "tableFilters.has_mfa"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("DataTable", {
        ref: "usersDatatable",
        attrs: { options: _vm.tableOptions, visibility: _vm.visibility },
        on: { "data-update": _vm.refreshDataTable }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }