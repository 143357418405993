var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ADialogLayout",
    {
      ref: "dialog",
      attrs: {
        title: "Nieuwe gebruiker toevoegen",
        confirmLabel: "Gebruiker toevoegen"
      },
      on: { confirm: _vm.createUser }
    },
    [
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-space-between": "",
            "align-baseline": ""
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "firstname",
                  name: "firstname",
                  required: "",
                  label: "Voornaam"
                },
                model: {
                  value: _vm.user.first_name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "first_name", $$v)
                  },
                  expression: "user.first_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "lastname",
                  name: "lastname",
                  required: "",
                  label: "Achternaam"
                },
                model: {
                  value: _vm.user.last_name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "last_name", $$v)
                  },
                  expression: "user.last_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "report_name",
                  name: "report_name",
                  required: "",
                  label: "Naam op schade rapport"
                },
                model: {
                  value: _vm.user.report_name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "report_name", $$v)
                  },
                  expression: "user.report_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "company_name",
                  name: "company_name",
                  required: "",
                  label: "Bedrijfsnaam"
                },
                model: {
                  value: _vm.user.company_name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "company_name", $$v)
                  },
                  expression: "user.company_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "email",
                  name: "email",
                  required: "",
                  label: "Email"
                },
                model: {
                  value: _vm.user.email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "business_email",
                  name: "business_email",
                  required: "",
                  label: "Bedrijf email"
                },
                model: {
                  value: _vm.user.business_email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "business_email", $$v)
                  },
                  expression: "user.business_email"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "phone",
                  name: "phone",
                  required: "",
                  label: "Telefoonnummer"
                },
                model: {
                  value: _vm.user.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "phone", $$v)
                  },
                  expression: "user.phone"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "password",
                  name: "password",
                  required: "",
                  label: "Wachtwoord"
                },
                model: {
                  value: _vm.user.password,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.userTypes,
                  "item-text": "name",
                  "item-value": "key",
                  label: "Type",
                  "persistent-hint": "",
                  "single-line": ""
                },
                model: {
                  value: _vm.user.type,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "type", $$v)
                  },
                  expression: "user.type"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "", "mb-3": "" } },
            [
              _c("p", { staticClass: "supervisor" }, [
                _vm._v("Kies een supervisor")
              ]),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.experts,
                  "item-text": "name",
                  "item-value": "uuid",
                  "hide-details": "",
                  placeholder: "Supervisor",
                  clearable: ""
                },
                on: { keyup: _vm.debouncedSearch },
                model: {
                  value: _vm.user.supervisor,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "supervisor", $$v)
                  },
                  expression: "user.supervisor"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }