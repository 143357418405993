import { Component, Vue } from 'vue-property-decorator';
import { User, UserImportResponse } from '@/models/User';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { identifyHostname } from '@/support/Client';
import { isAcceptance } from '@/support/ApplicationMode';

@Component<ImportUsersDialog>({})
export default class ImportUsersDialog extends Vue {
  // #region Class Properties
  protected importFileStep = 1;

  protected uploadedFile = '';

  protected uploadQueue: File[] = [];

  protected userImportResponse: UserImportResponse = {
    created: [],
    updated: [],
    failed: [],
  };
  // #endregion

  // #region Class Methods
  protected closeFileImportDialog() {
    this.importFileStep = 1;
    this.uploadQueue = [];
    this.uploadedFile = '';
    this.userImportResponse = {
      created: [],
      updated: [],
      failed: [],
    };
    this.$emit('toggle-dialog');
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }
    this.uploadQueue.push(file);
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected postUsersFile() {
    if (! this.uploadQueue.length) {
      return;
    }
    new User()
      .importUser({ file: this.uploadQueue[0] })
      .then((response: AxiosResponse) => {
        this.userImportResponse = response.data as UserImportResponse;
        this.importFileStep = 3;
        this.$emit('refresh-table');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected gotoStepOne() {
    if (this.uploadQueue.length) {
      return;
    }
    this.importFileStep = 1;
  }

  protected gotoStepTwo() {
    if (this.uploadQueue.length) {
      return;
    }
    this.importFileStep = 2;
  }
  // #endregion

  // #region Getters
  protected getResponseStatusText(status: string) {
    let text = '';

    if (status === 'created') {
      text = 'Aangemaakt';
    }

    if (status === 'updated') {
      text = 'Geüpdate';
    }

    if (status === 'failed') {
      text = 'Niet gelukt';
    }

    return text;
  }

  protected getResponseStatusIcon(status: string) {
    let icon = '';

    if (status === 'created') {
      icon = 'check';
    }

    if (status === 'updated') {
      icon = 'update';
    }

    if (status === 'failed') {
      icon = 'error';
    }

    return icon;
  }

  protected getResponseStatusColor(status: string) {
    let color = '';

    if (status === 'created') {
      color = 'success';
    }

    if (status === 'updated') {
      color = 'primary';
    }

    if (status === 'failed') {
      color = 'warning';
    }

    return color;
  }

  protected get excelApiUrl(): string {
    const filename = ! isAcceptance() ? 'gebruikerstemplate-acceptance' : 'gebruikerstemplate';
    return `${identifyHostname()}/assets/excel/${filename}.xlsx`;
  }
  // #endregion
}
